.navbar-profile-pic img{
    border-radius:  90px;
    height: 120px;
    width: 120px;
    display: flex;
    vertical-align: middle;
    margin: 0 auto;
}

.spacer{
    margin-top: 160px;
}
.nav-item{
    color: white;
    margin-top: 20px;
    margin-left: 5px;
}
.nav-link{
    margin-top: 20px;
    cursor: pointer;
}

.nav-link svg{
    margin-right: 10px;

}

.nav-link:hover{
    color: sandybrown;
}

.nav-link .active{
    color: sandybrown;
    border-left: 5px solid sandybrown;
    padding-left: 10px;
    text-decoration: none;
}