.contact{
    background-color: lightblue;
    color: white;
    display: flex;
    min-height: 50vh;
    font-family: 'Courier New', Courier, monospace;
}
.contact-container{
    margin-left: 200px;
    width: 100%;
    align-items: center;
    padding: 10px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 40px;
    
}
.contact-container img{
    width: 100%;
    height: 100%;
    margin-left: 40px;
    margin-right: 40px;
    align-items: center;

}

.contact-container .icon{
    padding-left: 25px;
    padding-right: 25px;
}

.contact-container h1{
    font-size: 50px;
    margin-top: 20px;
    align-items: center;
    text-align: center;
}

.contact-container h5{
    font-size: 100px;
    align-items: center;
    text-align: center;
}


.line{
    width: 45%;
    height: 2px;
    background-color: black;
    margin-top: 10px;

}

.contact-container a{
    text-decoration: none;
    color: black;
}
.contact-container .a :hover{
    color: dodgerblue;
}
#b :hover{
    color: darkgreen;
}
#c :hover{
    color:crimson;
}
.or{
    width: 10%;
    font-weight: bolder;
    text-align: center;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.card0{
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.2);
    border-radius: 0px;
    align-items: center;

}

input,textarea{
    padding: 10px 12px;
    border:1px solid lightgray;
    border-radius: 1px;
    margin-top: 5px;
    
}

button:focus{
    box-shadow: none;
    outline-width: 0px;
} 

.btn{
    background-color: dodgerblue;
    color: white;
    padding: 10px 12px;
    border: none;
    border-radius: 1px;
    cursor: pointer;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    font-size: 15px;
    font-weight: bolder;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.btn:hover{
    background-color: white;
    border-radius: 10px;
    border: 2px solid dodgerblue;
    color: #0d6efd;
    transition: .3s;
}

