.experience{
    background-color: black;
    color: white;
    display: flex;
    font-family: 'Courier New', Courier, monospace;
}
.experience-container{
    margin-left: 190px;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
}
.experience hr{
    width: 70%;
    margin:10px auto;
}

.vertical-timeline-element--edu{
    font-size: xx-small;
}