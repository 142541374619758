.sidebar-section{
    display: flex;
    background-color: black;

}
.sidebar{
    height: 100%;
    background-color: #36454F;
    width: 80px;
    position: fixed;
}

.sidebar-toggle{
    width: 200px;
}
.sidebar-toggle-icons{
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    cursor: pointer;
    margin-left: 20px;
}

.sidebar-toggle-icons-div svg{
    color: whitesmoke;
    margin-top: 30px;
}

@media (max-width: 600px){
    .sidebar{
        display: none;
    }
}
    